<template>
    <div class="loginBody">
        <div
            style="
                width: 98%;
                text-align: center;
                color: white;
                padding: 8vh 100px 0 0;
                -webkit-text-stroke: 1px black;
                font-size: 3vh;
            "
        >
            <h1>欢迎访问系统</h1>
        </div>

        <div class="loginDiv">
            <div class="login-content">
                <el-row>
                    <el-col style="text-align: center; padding: 25px 0 25px 0">
                        <i
                            class="iconfont icon-r-building"
                            style="font-size: 36px"
                        ></i>
                        <b style="font-size: 36px"> 假条管理系统</b>
                    </el-col>
                </el-row>
                <el-form
                    :model="loginForm"
                    label-width="100px"
                    :rules="rules"
                    ref="loginForm"
                >
                    <el-form-item label="姓名" prop="no">
                        <el-input
                            style="width: 200px"
                            type="text"
                            v-model="loginForm.no"
                            autocomplete="off"
                            @change="check()"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input
                            style="width: 200px"
                            type="password"
                            v-model="loginForm.password"
                            show-password
                            autocomplete="off"

                            @keyup.enter.native="confirm"
                        ></el-input>
                    </el-form-item>
                      <el-form-item label="验证码" prop="code">
                       <el-input
                            style="width: 100px "
                            type="text"

                            v-model="loginForm.code"
                            autocomplete="off"
                        ></el-input>
                        <img
                            style="width: 100px; height: 30px; margin-left: 30px"
                            :src="codeUrl"

                         alt="code"/>

                    </el-form-item>

                    <el-form-item style="padding-left: 5px">
                        <el-button
                            type="primary"
                            @click="codeVerity"
                            :disabled="confirm_disabled"
                            style="font-size: 22px"
                        >
                            登 录</el-button
                        >
                      <el-button
                          type="primary"
                          @click="confirmRegister"
                          :disabled="confirm_disabled"
                          style="font-size: 22px"
                      >
                        注册</el-button
                      >
                    </el-form-item>
                </el-form>
              <el-button
                  type="success"
                  @click="phoneLogin"
                  style="font-size: 10px ;margin-left: 105px"

              >
                手机号登录</el-button
              >
            </div>
        </div>
    </div>
</template>

<script>
import phoneLogin from "@/components/PhoneLogin.vue";

export default {
    name: "Login",
  computed: {},
    data() {
        return {
          codeUrl:'',
          sessionId:'',
          registerDialogVisible: false, // 控制弹窗显示
          confirm_disabled: false, //防止多次提交
          registerForm: {
            username: '',
            password: '',
            confirmPassword: '',
            agree: false
            },
            loginForm: {
                no: "",
                password: "",
            },
            rules: {
                no: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                ],
                password: [
                    { required: true, message: "请输密码", trigger: "blur" },
                ],
            },
        };
    },

    created() {
      this.checkDevice();
      this.getCode();
    },
    methods: {
      phoneLogin() {
        this.$router.push("/phoneLogin")
      },
      checkDevice() {
        // 检查是否为桌面设备
        const isDesktop = /Windows|Macintosh|Linux/i.test(navigator.userAgent) && !/Mobi|Android|iOS|iPad|iPod|iPhone|Windows Phone|IEMobile|BlackBerry|BB10|Opera Mini/i.test(navigator.userAgent);
        this.isDesktop = isDesktop;

        if (isDesktop) {
          // 例如重定向到首页或其他指定页面
          window.location.href = 'https://www.imut.edu.cn';
          // 如果是桌面设备，则重定向到其他页面或显示提示信息
          alert('此页面仅支持移动设备访问！');
        }
      },
        getCode() {
          this.codeUrl=this.$httpUrl + "/user/code";
        },
        codeVerity() {
          this.sessionId = document.cookie.match(/haha=([^;]+)/)[1];
          console.log("haha"+this.sessionId);
          this.$axios
            .post(this.$httpUrl + "/user/codeVerity", {
              code: this.loginForm.code,
              sessionId: this.sessionId
            })
            .then((res) => res.data)
            .then((res) => {
              if (res.code == 200) {
                //校验成功
               this.confirm();
              } else {
                this.confirm_disabled = false;
                alert("校验失败，验证码错误！");
                location.reload();
               return false;
              }
            })
          return false;
        },
      check() {
        if(this.loginForm.no=="迟皓"){
          alert("迟皓禁止使用")
        }
      },
      confirm() {
            this.confirm_disabled = true;
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    //valid成功为true，失败为false
                    //去后台验证用户名密码
                    this.$axios
                        .post(this.$httpUrl + "/user/login", this.loginForm)
                        .then((res) => res.data)
                        .then((res) => {
                            if (res.code == 200) {
                                //存储
                                sessionStorage.setItem(
                                    "CurUser",
                                    JSON.stringify(res.data.user)
                                );

                                console.log(res.data.menu);
                                this.$store.commit("setMenu", res.data.menu);
                                //跳转到主页
                                this.$router.replace("/Index");

                            } else {
                                this.confirm_disabled = false;
                                alert("校验失败，用户名或密码错误！");
                                return false;
                            }
                        })
                        .catch((e) => {
                            this.confirm_disabled = false;
                            console.log(e);
                            if (
                                e.response == undefined ||
                                e.response.data == undefined
                            ) {
                                this.$message({
                                    showClose: true,
                                    message: e,
                                    type: "error",
                                    duration: 5000,
                                });
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: e.response.data,
                                    type: "error",
                                    duration: 5000,
                                });
                            }
                        });
                } else {
                    this.confirm_disabled = false;
                    return false;
                }
            });
        },
      confirmRegister() {
          this.$router.push('/register');
      }
    },
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
html,
body {
    overflow-x: hidden;
}

.loginBody {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: #B3C0D1; */
    background: url("../assets/home.jpg");
    background-size: 100% 100%;

    overflow-x: hidden;
}
.loginDiv {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -200px;
    margin-left: -250px;
    width: 400px;
    height: 400px;
    background: #fff;
    border-radius: 5%;
    opacity: 0.9;
}
.login-title {
    margin: 20px 0;
    text-align: center;
}
.login-content {
    width: 400px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px;
}
</style>
